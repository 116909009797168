import { ZeroAccident } from 'generated/backendSchemas'
import { format } from 'date-fns'

import './style.css'
import './vibration.css'
import './schedule.css'
import './font.css'

export const ExamplePageDefault = ({ display_date, contract_name, start_date, target_days, diff }: ZeroAccident) => {
  return (
    <div className="container schedule">
        <main className="contents">
          <section className="schedule-content" style={{ height : '100svh', width : '100svw' }}>
            <div style={{ height: '74svh', display: 'flex', flexFlow: 'column', alignItems: 'center', justifyContent: 'space-around' }}>
              <div style={{ color: 'white', fontSize: '5svh', fontWeight : 'bold', width: '100%', textAlign : 'left', marginTop : '2svh' }}>無災害記録表</div>
              <div style={{ color: 'white', fontSize: '6svh', fontWeight : 'bold', width: '100%', textAlign : 'left', marginTop : '2svh' }}>{ contract_name }</div>
              <div style={{ fontFamily : 'DigitalNumbers', width : '90svw', textAlign : 'right', fontSize : '16svh', display : 'flex', flexFlow: 'column', alignItems: 'right', justifyContent: 'space-around', gap : '1svw'}}>
                <div style={{ color: 'white', width: '80svw', display : 'flex', justifyContent : 'end' }}>
                  <div>
                    {display_date && format(new Date(display_date), 'MM')}<span style={{ fontSize : '8svh' }}>月</span>{display_date && format(new Date(display_date), 'dd')}<span style={{ fontSize : '8svh' }}>日</span>
                  </div>
                  <div style={{ width: '10svw' }}>
                    <span style={{ fontSize : '4svh' }}>現在</span>
                  </div>
                </div>
                <div style={{ color: 'white', width: '80svw', display : 'flex', justifyContent : 'end' }}>
                  <div>
                    { diff }
                  </div>
                  <div style={{ width: '10svw' }}>
                    <span style={{ fontSize : '4svh' }}>日</span>
                  </div>
                </div>
                <div style={{ color: 'white', width: '80svw', display : 'flex', justifyContent : 'end' }}>
                  <div>
                    { target_days }
                  </div>
                  <div style={{ width: '10svw' }}>
                    <span style={{ fontSize : '4svh' }}>日</span>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </main>
    </div>
  )
}
