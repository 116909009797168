import illustTopWhitePng from 'src/assets/images/schedule/illust_top_white.png'
import iconMagnetBluePng from 'src/assets/images/schedule/icon_magnet_blue.png'
import iconMagnetGreenPng from 'src/assets/images/schedule/icon_magnet_green.png'
import iconMagnetRedPng from 'src/assets/images/schedule/icon_magnet_red.png'
import iconMagnetYellowPng from 'src/assets/images/schedule/icon_magnet_yellow.png'
import mainTitlePng from 'src/assets/images/schedule/main_title.png'
import mainTitle2Png from 'src/assets/images/schedule/main_title@2x.png'
// import sampleLogoPng from 'src/assets/images/schedule/sample_logo.png'
import stationaryPng from 'src/assets/images/schedule/stationary.png'
import subTitlePng from 'src/assets/images/schedule/sub_title.png'
import subTitle2Png from 'src/assets/images/schedule/sub_title@2x.png'
import { FloatingText } from '../../FloatingText'
import { Schedule } from 'generated/backendSchemas'
import { getAdditionalClass } from '../../util'

import './style.css'
import './schedule.css'

export const SchedulePageDefault = ({ schedule, message, logo }: Schedule) => {
  const wwwUrl = (import.meta.env.VITE_WWW_URL ?? '') as string
  
  return (
  <div className="container schedule">
    <div className="page-contents">
      <header className="header">
        <h1 className="header__title" style={{ fontSize : '4vw' }}>ご近隣・ご通行中の皆さまへ</h1>
        <div>
            <img src={wwwUrl + logo} style={{ height : '10svh' }} />
        </div>
          {/* <div className="header__datetime">
          <div className="header__date">
            <span>4</span>
            <span className="header__datetime-unit">月</span>
            <span>17</span>
            <span className="header__datetime-unit">日</span>
          </div>
          <div className="header__time">
            <span className="header__datetime-unit header__time-unit">AM</span>
            <span>10:30</span>
          </div>
        </div> */}
      </header>

      <main className="contents">
        <section className="schedule-content">
          <h2 className="schedule-content__title">本日以降の作業予定</h2>
            <table style={{ width: '100%', fontSize: '2vw', fontWeight: 'bold', borderSpacing: '0.5vw' }}>
              <tbody>
          {(schedule ?? []).map((item, index) => {
            const additionalClass =
              item.day_of_week === '祝'
                ? '#DF4747'
                : item.day_of_week === '土'
                ? '#005EAC'
                : item.day_of_week === '日'
                ? '#DF4747'
                : item.is_holiday === true
                ? '#DF4747'
                : ''
            return (
              <tr key={item.date} style={{ backgroundColor : 'white' }}>
                <td style={{ width : '20%', padding : '0.5vw' }}>{item.date} <span style={{ color : additionalClass }}>{item.day_of_week}</span></td>
                <td style={{ padding : '0.5vw' }}>{item.schedule_text}</td>
              </tr>
            )
          })}
            </tbody>
          </table>
        </section>
      </main>

      </div>


      <footer className="footer">
        <div className="footer__note">
          <div className="marquee">
            <span>{ message }</span>
          </div>
        </div>
      </footer>

    </div>
  )
}
