import { Util } from 'src/utils'
import { formatDate } from 'src/utils/formatDate'
import { Props } from '../../types'
import { getCharacterImageByWbgt, getBackgroundColorByWbgt, getTextImagesByWbgt, getLevelImageByWbgt } from './utils'
import { format } from 'date-fns'

// import './normalize.css'
import './style.css'
import './wbgt.css'

import redFace from 'src/assets/images/heatstroke/new/img_WBGT_red.svg'

export const HeatStrokePageDefault = ({ data }: Props) => {
  const wbgt = data.value ?? 0
  const area = data.area ?? ''
  const dangerLevel = Util.wbgt.getDangerLevel(wbgt)
  const characterImages = getCharacterImageByWbgt(wbgt)
  const levelImage = getLevelImageByWbgt(wbgt)
  const backgroundColorClass = "wbgt-content " + getBackgroundColorByWbgt(wbgt)

  const textImages = getTextImagesByWbgt(wbgt)
  
  return (
  <div className="container vibration">
    <div className="page-contents" style={{ height : '95svh' }}>
      <header className="header">
        <h1 className="header__title">WBGT（暑さ指数）</h1>
        <div className="header__datetime">
          <div className="header__date">
              <span>{ data.display_date && format(new Date(data.display_date), 'M') }</span>
            <span className="header__datetime-unit">月</span>
              <span>{ data.display_date && format(new Date(data.display_date), 'd') }</span>
            <span className="header__datetime-unit">日</span>
          </div>
          <div className="header__time">
            <span className="header__datetime-unit header__time-unit">{ data.display_date && format(new Date(data.display_date), 'a') }</span>
              <span>{ data.display_date && format(new Date(data.display_date), 'HH:mm') }</span>
          </div>
        </div>
      </header>

      <main className="contents" style={{ height : '76svh' }}>
        <section className={backgroundColorClass} style={{ height : '76svh' }}>
          <div className="wbgt-content__sign">
            <div style={{ color : 'white', textAlign : 'center', fontSize: '6svh', fontWeight: 'bold' }}>{area}</div>
            <div className="wbgt-content__sign-img" style={{ display : 'flex', justifyContent : 'center', height : '60%', marginTop : '5%'}}><img src={characterImages}  style={{ height : '100%'}} /></div>
            <p className="wbgt-content__sign-description" style={{ height: '20%' }}><img src={textImages[0]} style={{ height : '80%'}}  /></p>
          </div>
          <div className="wbgt-content__list" style={{ height : '65svh' }}>
            <section className="wbgt-content__list-item">
              <h3 className="wbgt-content__list-item-title">WBGT値</h3>
              <div className="wbgt-content__list-item-value" style={{ fontSize : '16svw' }}>{ wbgt}</div>
              <div className="wbgt-content__list-item-unit">℃</div>
            </section>
            <section className="wbgt-content__list-item">
                <h3 className="wbgt-content__list-item-title">警戒レベル</h3>
                <img src={levelImage} style={{ height : '45svh', margin : 'auto' }} />
                <div style={{ fontSize : '4svh', fontWeight : 'bold', margin : 'auto' }}>レベル { dangerLevel }</div>
              <div className="wbgt-content__list-item-unit"></div>
            </section>
          </div>
        </section>
      </main>
    </div>
    <footer className="footer" style={{ height : '5svh' }}>
      <div className="footer__note">
        <div className="marquee">
          <span>{Util.wbgt.getDescriptionByWbgt(wbgt)}</span>
        </div>
      </div>
    </footer>
  </div>
  )
}
